import loadable from '@loadable/component';
import { RouteProps } from 'react-router-dom';

const IndexPage = loadable(() => import('./IndexPage'));
const RetailersPage = loadable(() => import('./Retailers'));
const ManagementPage = loadable(() => import('./Management'));
const WorkOrdersPage = loadable(() => import('./WorkOrders'));
const RegisterPage = loadable(() => import('./Register'));
const EmailTemplatePage = loadable(() => import('./EmailTemplate'));
const TermsOfUsePage = loadable(() => import('./TermsOfUse'));
const PolicyPage = loadable(() => import('./Policy'));
const DiamondSupplier = loadable(() => import('./Register/DiamondSupplier'));
const UnsubscribeNewsletter = loadable(() => import('./UnsubscribeNewsletter'));

const routes: RouteProps[] = [
  { exact: true, path: '/', component: IndexPage },
  { exact: true, path: '/retailers', component: RetailersPage },
  { exact: true, path: '/management', component: ManagementPage },
  { exact: true, path: '/work-orders', component: WorkOrdersPage },
  { exact: true, path: '/diamond-supplier', component: DiamondSupplier },
  { path: '/register/new-firm', component: RegisterPage },
  { exact: true, path: '/register/:type?', component: RegisterPage },
  { exact: true, path: '/email-template', component: EmailTemplatePage },
  { exact: true, path: '/terms-of-use', component: TermsOfUsePage },
  { exact: true, path: '/privacy-policy', component: PolicyPage },
  { exact: true, path: '/unsubscribe', component: UnsubscribeNewsletter },
];

export default routes;
