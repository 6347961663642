/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  InMemoryCacheConfig,
} from '@apollo/client';
import fetch from 'cross-fetch';

declare global {
  interface Window {
    __APOLLO_STATE__?: any;
  }
}

export default function createClient(): ApolloClient<InMemoryCacheConfig> {
  const ssrMode = typeof window === 'undefined';

  const client = new ApolloClient({
    ssrMode,
    cache: new InMemoryCache(),
    link: new HttpLink({
      fetch,
      uri: process.env.RAZZLE_APP_API_URL || 'http://localhost:8880/graphql',
    }),
  });

  if (!ssrMode) {
    client.restore(window.__APOLLO_STATE__);
  }

  return client;
}
