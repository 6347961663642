import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import loadable from '@loadable/component';
import './assets/index.scss';
import './assets/buttons.scss';
import './assets/mobile.scss';
import 'react-toastify/dist/ReactToastify.css';
import pages from './pages';
import 'bootstrap/dist/css/bootstrap.min.css';

const AuthenticationProvider = loadable(
  () => import('@wgt/authentication/AuthenticationProvider'),
);

const App: React.FC = () => {
  return (
    <AuthenticationProvider>
      <>
        <ToastContainer />
        <Switch>
          {pages.map((pageProps, index) => (
            <Route
              {...pageProps}
              key={`route-${pageProps.path ?? 'unknown'}-${index}`}
            />
          ))}
          <Route render={() => <Redirect to="/" />} />
        </Switch>
      </>
    </AuthenticationProvider>
  );
};

export default App;
