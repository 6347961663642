/* eslint-disable @typescript-eslint/no-explicit-any */
import { BrowserRouter } from 'react-router-dom';
import React from 'react';
import { hydrate } from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import { loadableReady } from '@loadable/component';
import App from './App';
import createApolloClient from './apollo';
const client = createApolloClient();

loadableReady().then(() =>
  hydrate(
    <ApolloProvider client={client}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ApolloProvider>,
    document.getElementById('root'),
  ),
);
declare const module: any;
if (module.hot) {
  module.hot.accept();
}
